import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import ThreeTwo from "../images/three-two";

//Styles
import { borderColor, linkColor } from "../styles/colors";

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  if (property === "title") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.title.text.localeCompare(a.node.data.title.text);
      } else {
        return a.node.data.title.text.localeCompare(b.node.data.title.text);
      }
    };
  } else if (property === "tags") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.tags[0].localeCompare(a.node.tags[0]);
      } else {
        return a.node.tags[0].localeCompare(b.node.tags[0]);
      }
    };
  } else {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data[property].localeCompare(a.node.data[property]);
      } else {
        return a.node.data[property].localeCompare(b.node.data[property]);
      }
    };
  }
}

const Row = styled.div`
  display: grid;
  grid-gap: 25px;

  padding: 0 0 40px 0;

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1399px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  &:hover > .project h3 {
    color: ${linkColor};
  }

  &:hover > .project:hover h3 {
    color: #000;
  }

  @media (hover: none) and (pointer: coarse) {
    &:hover > .project h3,
    &:hover > .project,
    &:hover > .project:hover,
    &:hover > .project:hover {
      opacity: 1;
      color: #000;
    }
  }
`;

const SlideoutContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 100%;

  background-color: #fff;

  transform: translateY(
    ${props => (props.isSlideoutOpen ? "36px" : "calc(100% - 37px)")}
  );
  transition: 500ms transform cubic-bezier(0.67, 0.41, 0.37, 0.63);

  display: flex;
  min-height: 100%;
  flex-direction: column;

  z-index: 300;

  @media (max-width: 767px) {
    transform: translateY(
      ${props => (props.isSlideoutOpen ? "0" : "calc(100% - 38px)")}
    );
  }
`;

const SlideoutBar = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  line-height: 1;

  border-top: 1px solid ${borderColor};

  padding: 12px 20px 9px 20px;

  z-index: 1000;
  height: initial;

  @media (max-width: 767px) {
    padding: 12px 10px 9px 10px;

    border-top: ${props =>
      props.isSlideoutOpen ? "none" : `1px solid ${borderColor}`}
    };
  }
`;

const SlideoutTrigger = styled.button`
  line-height: 1;

  &:hover {
    color: ${linkColor};
  }
`;

const Projects = styled.div`
  padding: 20px;

  flex: 1;
  z-index: 1;

  overflow-y: auto;

  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;

const Project = styled.div`
  position: relative;

  & h3 {
    font-size: 15px;
    text-align: center;

    margin-top: 5px;
  }

  &:hover h3 {
    color: ${linkColor};
  }
`;

export const ProjectsPageSlideout = ({
  projects,
  isSlideoutOpen,
  onClick,
  projectsActiveFilter,
}) => {
  const projectsGrid = projects
    .filter(project => project.node.data.project_year !== null)
    .filter(project => {
      if (projectsActiveFilter === "All") {
        return project;
      }

      if (projectsActiveFilter !== "All") {
        return project.node.tags.includes(projectsActiveFilter);
      }
    })
    .sort(dynamicSort(`-project_year`))
    .map((project, index) => (
      <div key={project.node.prismicId} className="project">
        <Project>
          <Link to={`/projects/${project.node.uid}`}>
            {project.node.data.body3[0].slice_type === "image" && (
              <ThreeTwo
                className="image"
                key={index}
                image={project.node.data.body3[0].primary.image.url}
                alt={project.node.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
              />
            )}

            {project.node.data.body3[0].slice_type === "full_bleed_gallery" && (
              <ThreeTwo
                className="full_bleed_gallery"
                key={index}
                image={project.node.data.body3[0].primary.image.url}
                alt={project.node.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                objectFit={`cover !important`}
              />
            )}

            {project.node.data.body3[0].slice_type === "bg_color_gallery" && (
              <ThreeTwo
                className="bg_color_gallery"
                key={index}
                image={project.node.data.body3[0].primary.image.url}
                alt={project.node.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                backgroundColor={
                  project.node.data.body3[0].primary.background_color
                }
                padding={
                  project.node.data.body3[0].primary.background_color !==
                  undefined
                    ? `20px 0`
                    : `0`
                }
                objectFit={`contain`}
              />
            )}

            {project.node.data.body3[0].slice_type ===
              "gallery_with_background_image" && (
              <ThreeTwo
                key={index}
                image={project.node.data.body3[0].primary.image.url}
                alt={project.node.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                backgroundImage={
                  project.node.data.body3[0].primary.background_image.url
                }
                padding={`20px 0`}
                objectFit={`contain`}
              />
            )}

            {project.node.data.body3[0].slice_type ===
              "image_with_video_bg" && (
              <ThreeTwo
                image={project.node.data.body3[0].primary.image.url}
                alt={project.node.data.body3[0].primary.image.alt}
                lazy={false}
                size={800}
                padding={`20px 0`}
                video={project.node.data.body3[0].primary.video.url}
                key={index}
                objectFit={`contain`}
              />
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: project.node.data.title.html,
              }}
            />
          </Link>
        </Project>
      </div>
    ));

  return (
    <SlideoutContainer isSlideoutOpen={isSlideoutOpen}>
      <SlideoutBar isSlideoutOpen={isSlideoutOpen}>
        <SlideoutTrigger onClick={() => onClick()}>
          Visual Index {isSlideoutOpen === false ? "+" : "–"}
        </SlideoutTrigger>
      </SlideoutBar>

      <Projects>
        <Row>{projectsGrid}</Row>
      </Projects>
    </SlideoutContainer>
  );
};
