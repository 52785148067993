import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import LazyLoad from "react-lazyload";

// Context
import ContextConsumer from "../components/context/projects-table-header-filter";
import { ProjectsActiveFilterConsumer } from "../components/context/projects-active-filter";

// Components
import { ProjectsPageSlideout } from "../components/projects/projects-page-slideout";

// Styles
import { borderColor } from "../components/styles/colors";

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 *
 * @param {String} property Key of the object to sort.
 */
function dynamicSort(property) {
  var sortOrder = 1;

  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }

  if (property === "title") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data.title.text.localeCompare(a.node.data.title.text);
      } else {
        return a.node.data.title.text.localeCompare(b.node.data.title.text);
      }
    };
  } else if (property === "tags") {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.tags[0].localeCompare(a.node.tags[0]);
      } else {
        return a.node.tags[0].localeCompare(b.node.tags[0]);
      }
    };
  } else {
    return function(a, b) {
      if (sortOrder === -1) {
        return b.node.data[property].localeCompare(a.node.data[property]);
      } else {
        return a.node.data[property].localeCompare(b.node.data[property]);
      }
    };
  }
}

const Page = styled.div`
  padding: 0;

  font-size: 15px;

  & h3 {
    font-size: 15px;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 0 0 36px 0;
`;

const TableRow = styled.div`
  width: 100%;

  border-bottom: 1px solid ${borderColor};

  transition: 250ms background-color ease;

  &:hover {
    background-color: #f0f0f0;
    margin-top: -1px;
    padding-top: 1px;

    border-bottom: 1px solid transparent;
  }

  & a {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const TableInnerRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 2fr minmax(50px, 200px) minmax(50px, 100px);

  cursor: pointer;

  @media (max-width: 900px) {
    grid-template-columns: 2fr 3fr 2fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 2fr 3fr 1fr;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr 70px;
  }
`;

const TableCol = styled.div`
  padding: 9px 14px 6px 14px;

  &:first-of-type {
    padding: 9px 14px 6px 20px;
  }

  &:last-of-type {
    padding: 9px 20px 6px 14px;
  }

  @media (max-width: 900px) {
    display: ${props => (props.mediumScreen ? props.mediumScreen : "block")};
  }

  @media (max-width: 800px) {
    display: ${props => (props.tabletScreen ? props.tabletScreen : "block")};
  }

  @media (max-width: 767px) {
    display: ${props => (props.mobileScreen ? props.mobileScreen : "block")};
    padding: 10px 10px 8px 10px;
  }
`;

const Client = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Projects = ({ data }) => {
  const [isSlideoutOpen, setIsSlideoutOpen] = useState(false);

  const slideoutProjects = data.allPrismicProject.edges.filter(
    project =>
      project.node.data.body3[0] !== undefined &&
      project.node.data.body4[0] !== undefined
  );

  return (
    <ContextConsumer>
      {({ activeTableFilter }) => (
        <ProjectsActiveFilterConsumer>
          {({ projectsActiveFilter }) => {
            const allProjects = data.allPrismicProject.edges
              .filter(project => project.node.data.project_year !== null)
              .filter(project => {
                if (projectsActiveFilter === "All") {
                  return project;
                } else {
                  return project.node.tags.includes(projectsActiveFilter);
                }
              })
              .sort(dynamicSort(activeTableFilter))
              .map(project => (
                <LazyLoad height={40} key={project.node.prismicId}>
                  <TableRow>
                    <Link to={`/projects/${project.node.uid}`}>
                      <TableInnerRow>
                        <TableCol mobileScreen={`none`}>
                          {project.node.data.client}
                        </TableCol>
                        <TableCol>
                          <Client>{project.node.data.client}</Client>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: project.node.data.title.html,
                            }}
                          />
                        </TableCol>
                        <TableCol mobileScreen={`none`} tabletScreen={`none`}>
                          {project.node.tags
                            .sort()
                            .map((tag, index) =>
                              index !== project.node.tags.length - 1
                                ? `${tag}, `
                                : tag
                            )}
                        </TableCol>
                        <TableCol
                          mediumScreen={`none`}
                          tabletScreen={`none`}
                          mobileScreen={`none`}
                        >
                          {project.node.data.discipline}
                        </TableCol>
                        <TableCol>
                          {project.node.data.project_year !== null &&
                            project.node.data.project_year.split("-")[0]}
                        </TableCol>
                      </TableInnerRow>
                    </Link>
                  </TableRow>
                </LazyLoad>
              ));

            return (
              <>
                <Helmet
                  meta={[
                    {
                      name: "og:url",
                      content: `https://www.wolfehall.com/projects/`,
                    },
                  ]}
                />
                <Page>
                  <Table>{allProjects}</Table>

                  <ProjectsPageSlideout
                    projects={slideoutProjects}
                    isSlideoutOpen={isSlideoutOpen}
                    projectsActiveFilter={projectsActiveFilter}
                    onClick={() => setIsSlideoutOpen(!isSlideoutOpen)}
                  />
                </Page>
              </>
            );
          }}
        </ProjectsActiveFilterConsumer>
      )}
    </ContextConsumer>
  );
};

export default Projects;

export const pageQuery = graphql`
  query Archive {
    allPrismicProject(
      sort: { fields: data___project_year }
      filter: { data: { private_project: { ne: true } } }
    ) {
      edges {
        node {
          prismicId
          uid
          tags
          data {
            title {
              html
              text
            }
            client
            project_year
            discipline

            body4 {
              ... on PrismicProjectBody4Image {
                id
                slice_type
                primary {
                  image {
                    url
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                  padding
                }
              }
              ... on PrismicProjectBody4FullBleedImage {
                id
                primary {
                  image {
                    url
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicProjectBody4ImageWithBgColor {
                id
                slice_type
                primary {
                  background_color
                  padding
                  image {
                    url
                    dimensions {
                      height
                      width
                    }
                  }
                }
              }
            }
            body3 {
              ... on PrismicProjectBody3Image {
                slice_type
                primary {
                  image {
                    url
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                  padding
                }
                id
              }
              ... on PrismicProjectBody3FullBleedGallery {
                slice_type
                primary {
                  image {
                    url
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                }
                id
              }
              ... on PrismicProjectBody3GalleryWithBackgroundImage {
                id
                slice_type
                primary {
                  image {
                    alt
                    dimensions {
                      height
                      width
                    }
                    url
                  }
                  padding
                  background_image {
                    url
                    alt
                  }
                }
              }
              ... on PrismicProjectBody3BgColorGallery {
                id
                slice_type
                primary {
                  background_color
                  padding
                  image {
                    alt
                    url
                    dimensions {
                      height
                      width
                    }
                  }
                }
              }
              ... on PrismicProjectBody3ImageWithVideoBg {
                id
                slice_type
                primary {
                  padding
                  image {
                    url
                    dimensions {
                      height
                      width
                    }
                    alt
                  }
                  video {
                    url
                  }
                }
              }
              ... on PrismicProjectBody3FullBleedVideo {
                id
                primary {
                  video {
                    url
                  }
                }
                slice_type
              }
              ... on PrismicProjectBody3Video {
                id
                slice_type
                primary {
                  padding
                  video {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
